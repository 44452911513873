import { Button, Input, InputNumber, message, Select } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { formatNumber, multiple, toFixed } from 'egenie-common';
import type { BaseData, PaginationData, ValueAndLabelData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, request } from 'egenie-utils';
import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import { Observer } from 'mobx-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React from 'react';
import type { VendorItem } from '../../../utils';
import { getConsignor, getOwner, getWarehouse, getWarehouseArea, isShareOwner, playVoice, voiceData } from '../../../utils';
import type { StockDetailItem, StockMainItem } from '../types';
import { onlyId, skuCondition, STOCK_IN_ORDER_TYPE } from '../utils';

export class AddAndEditStore {
  constructor(vendorIdData: VendorItem[], callback: () => void, mainItem?: StockMainItem) {
    this.vendorIdData = vendorIdData;
    this.mainItem = mainItem;
    this.callback = callback;
  }

  private getOwnerData = (otherStockOrderType?: string | number, params?: any): Promise<ValueAndLabelData> => {
    if ([
      STOCK_IN_ORDER_TYPE.consignorStockIn,
      STOCK_IN_ORDER_TYPE.selfStockIn,
    ].includes(Number(otherStockOrderType))) {
      return getConsignor()
        .then((data) => this.ownerData = data);
    } else {
      return getOwner(params)
        .then((data) => this.ownerData = data);
    }
  };

  @computed
  public get isEdit(): boolean {
    return !_.isEmpty(this.mainItem);
  }

  @computed
  public get showVendorId(): boolean {
    return [
      STOCK_IN_ORDER_TYPE.vendorStockIn,
      STOCK_IN_ORDER_TYPE.consignorStockIn,
    ].includes(Number(this.otherStockOrderType));
  }

  @observable public otherStockOrderType: number | string = null;

  @computed public get isVendorStockIn() {
    return Number(this.otherStockOrderType) === STOCK_IN_ORDER_TYPE.vendorStockIn;
  }

  @computed
  public get ownerIdDisabled(): boolean {
    return this.isEdit || this.isVendorStockIn;
  }

  @action public handleOtherStockOrderTypeChange = (otherStockOrderType: number): void => {
    this.otherStockOrderType = otherStockOrderType;
    this.formRef.current.setFieldsValue({
      vendorId: undefined,
      ownerId: undefined,
      pledge: false,
    });
    const formData = this.formRef.current.getFieldsValue();
    if (!formData.warehouseId || !formData.warehouseAreaId) {
      const error = '请选择仓库和库区';
      this.otherStockOrderType = null;
      message.warn({
        key: error,
        content: error,
      });
      setTimeout(() => this.formRef.current.setFieldsValue({ otherStockOrderType: undefined }));
      return;
    }

    this.getUnusedWarehouseBin(true);
    this.getOwnerData(otherStockOrderType)
      .then(() => {
        if (this.isVendorStockIn) {
          this.formRef.current.setFieldsValue({ ownerId: this.ownerData.find((item) => isShareOwner(item.value))?.value });
        }
      });
  };

  @action public handleVendorIdChange = (vendorId: string) => {
    const vendorItem = this.vendorIdData.find((item) => item.value === `${vendorId }`);
    if (`${this.otherStockOrderType}` === `${STOCK_IN_ORDER_TYPE.vendorStockIn}` && vendorItem && !vendorItem.stockToWarehouse) {
      const error = '该供应商不是备货入仓供应商,不可按供应商入库';
      message.warn({
        key: error,
        content: error,
      });
      setTimeout(() => {
        this.formRef.current?.setFieldsValue({ vendorId: undefined });
      });
    } else {
      this.mainSubStructureModel.gridModel.resetAll();
      this.mainSubStructureModel.gridModel.rows = [];
    }
  };

  @observable.ref public vendorIdData: VendorItem[] = [];

  private callback?: () => void = null;

  public init = () => {
    getWarehouse()
      .then((data) => this.warehouseData = data);
    if (this.mainItem) {
      const {
        warehouseId,
        warehouseAreaId,
        ownerId,
        otherStockOrderType,
        otherStockOrderDate,
        note,
        vendorId,
        pledge,
      } = this.mainItem;

      this.otherStockOrderType = Number(this.mainItem.otherStockOrderType);

      this.formRef.current.setFieldsValue({
        pledge: Boolean(pledge),
        warehouseId: _.toString(warehouseId) || undefined,
        warehouseAreaId: _.toString(warehouseAreaId) || undefined,
        ownerId: _.toString(ownerId) || undefined,
        vendorId: _.toString(vendorId) || undefined,
        otherStockOrderType: _.toString(otherStockOrderType) || undefined,
        otherStockOrderDate: moment(otherStockOrderDate || Date.now()),
        note: _.toString(note),
      });

      getWarehouseArea({ warehouseId: this.mainItem.warehouseId })
        .then((data) => this.warehouseAreaData = data);
      this.getOwnerData(this.mainItem.otherStockOrderType, { warehouseId: this.mainItem.warehouseId });
      this.getUnusedWarehouseBin(false);

      request<PaginationData<StockDetailItem>>({
        url: '/api/cloud/wms/rest/bill/other/detail/page',
        method: 'POST',
        data: {
          page: 1,
          pageSize: 10000,
          wmsOtherStockOrderId: this.mainItem.id,
        },
      })
        .then((info) => this.mainSubStructureModel.gridModel.rows = (info.data?.list || []).map((item) => ({
          ...item,
          warehouseBinId: _.toString(item.warehouseBinId) || undefined,
          warehouseAreaId: _.toString(item.warehouseAreaId) || undefined,
          stockNum: formatNumber(item.stockNum || item.number),
          number: formatNumber(item.number),
          costPrice: formatNumber(item.costPrice),
          _id: nanoid(),
        })));
    }
  };

  @observable.ref public mainItem?: StockMainItem = null;

  @observable public loading = false;

  public formRef = React.createRef<FormInstance>();

  @observable public warehouseData: ValueAndLabelData = [];

  @action public handleWarehouseChange = (warehouseId: string) => {
    this.formRef.current.setFieldsValue({
      warehouseAreaId: undefined,
      ownerId: undefined,
      vendorId: undefined,
    });

    getWarehouseArea({ warehouseId })
      .then((data) => this.warehouseAreaData = data);
    this.getOwnerData(this.formRef.current.getFieldsValue().otherStockOrderType, { warehouseId });
  };

  @observable public warehouseAreaData: ValueAndLabelData = [];

  @observable public ownerData: ValueAndLabelData = [];

  @observable public warehouseBinData: ValueAndLabelData = [];

  @action public getUnusedWarehouseBin = (clearTableData: boolean) => {
    if (clearTableData) {
      this.mainSubStructureModel.gridModel.resetAll();
      this.mainSubStructureModel.gridModel.rows = [];
    }

    request<BaseData<Array<{ serialNo: string; id: number | string; }>>>({
      url: '/api/cloud/baseinfo/rest/warehouse/bin/checkUnusedBin',
      method: 'post',
      data: this.formRef.current.getFieldsValue(),
    })
      .then((info) => {
        this.warehouseBinData = (info.data || []).map((item) => ({
          value: `${item.id}`,
          label: item.serialNo,
        }));
      });
  };

  @action public handleSubmit = async(): Promise<void> => {
    this.loading = true;
    try {
      const {
        note,
        ownerId,
        otherStockOrderDate,
        otherStockOrderType,
        warehouseAreaId,
        warehouseId,
        vendorId,
        pledge,
      } = await this.formRef.current.validateFields();
      const data = {
        note,
        ownerId,
        vendorId,
        pledge,
        vendorTenantId: this.vendorIdData.find((item) => item.value === vendorId)?.vendorTenantId,
        orderType: '1',
        otherStockOrderDate: moment(otherStockOrderDate)
          .format('YYYY-MM-DD'),
        otherStockOrderType,
        warehouseAreaId,
        warehouseId,
        id: this.mainItem?.id,
        sourceType: this.mainItem?.sourceType,
        stockDetails: this.mainSubStructureModel.gridModel.rows.map((row) => {
          const {
            warehouseBinId,
            skuId,
            productNo,
            batchNo = '',
            skuNo,
            expiryDate = '',
            productId,
            manufactureDate = '',
            number,
            costPrice,
            note,
            stockNum,
          } = row;
          return {
            ownerId,
            productNo,
            skuNo,
            warehouseBinId,
            productId,
            warehouseAreaId,
            skuId,
            batchId: '0',
            batchNo,
            expiryDate,
            manufactureDate,
            number,
            costPrice,
            note,
            stockNum,
          };
        }),
      };

      if (this.mainSubStructureModel.gridModel.rows.length === 0) {
        const error = '明细不能为空';
        message.error({
          key: error,
          content: error,
        });
        throw new Error(error);
      }

      await request({
        url: '/api/cloud/wms/rest/bill/other/add',
        method: 'post',
        data,
      });
      message.success('保存成功');
      if (typeof this.callback === 'function') {
        this.callback();
      }
    } finally {
      this.loading = false;
    }
  };

  @action public scanAddProduct = async(scanValue: string) => {
    if (!scanValue) {
      playVoice(voiceData.wrong);
      throw new Error('请输入');
    }

    try {
      this.mainSubStructureModel.gridModel.loading = true;

      const {
        warehouseId,
        warehouseAreaId,
        ownerId,
        scanKey,
        vendorId,
        otherStockOrderType,
      } = await this.formRef.current.validateFields();

      const data = {
        batch: false, // 单个添加
        page: 1,
        pageSize: 50,
        key: scanKey,
        value: scanValue,
        [Object.values(skuCondition)
          .find((val) => val.value === scanKey)?.paramsKey]: scanValue,
        warehouseId,
        warehouseAreaId,
        ownerId,
        vendorId,
        stock: false, // 入库单
        enable: 1,
        otherStockOrderType,
      };

      const info = await request<PaginationData<StockDetailItem>>({
        url: '/api/cloud/baseinfo/rest/sku/findByConditions',
        method: 'post',
        data,
      });
      const addData = (info.data?.list || []);

      if (addData.length === 0) {
        playVoice(voiceData.wrong);
        message.error('未查询到商品');
        throw new Error('未查询到商品');
      } else {
        playVoice(voiceData.di);
        addData.forEach((item) => {
          this.handleAddDetailItem(item);
        });
      }
    } finally {
      this.mainSubStructureModel.gridModel.loading = false;
      this.formRef.current.setFieldsValue({ scanValue: '' });
    }
  };

  @action public handleAddDetailItem = (addItem: StockDetailItem) => {
    const rows: StockDetailItem[] = this.mainSubStructureModel.gridModel.rows;
    const equalItem: StockDetailItem | null = rows.find((val) => onlyId(val) === onlyId({
      skuId: addItem.id,
      warehouseBinId: _.toString(addItem.warehouseBinId) || this.warehouseBinData[0]?.value,
    }));

    if (equalItem) {
      equalItem.number = formatNumber(equalItem.number) + (formatNumber(addItem.number) || 1);
    } else {
      this.mainSubStructureModel.gridModel.rows.push({
        ...addItem,
        _id: nanoid(),
        number: formatNumber(addItem.number) || 1,
        costPrice: formatNumber(addItem.costPrice),
        skuId: addItem.id,
        warehouseAreaId: _.toString(addItem.warehouseAreaId) || this.formRef.current?.getFieldValue('warehouseAreaId'),
        warehouseBinId: _.toString(addItem.warehouseBinId) || this.warehouseBinData[0]?.value,
      });
    }
  };

  @observable public showAddGoodsModal = false;

  @action public openAddGoodsModal = () => {
    this.formRef.current.validateFields()
      .then(() => {
        this.showAddGoodsModal = true;
      });
  };

  @action public closeAddGoodsModal = () => {
    this.showAddGoodsModal = false;
  };

  @action public addGoodsModalCallback = (rows: StockDetailItem[]) => {
    this.closeAddGoodsModal();
    rows.forEach((item) => {
      this.handleAddDetailItem(item);
    });
  };

  public mainSubStructureModel = new MainSubStructureModel({
    buttons: [],
    grid: {
      columns: [
        {
          key: 'operation',
          frozen: true,
          width: 80,
          name: '操作',
          formatter: ({ rowIdx }) => {
            return (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  this.mainSubStructureModel.gridModel.rows.splice(rowIdx, 1);
                }}
                type="link"
              >
                删除
              </Button>
            );
          },
        },
        {
          name: '库区',
          key: 'warehouseAreaId',
          width: 180,
          formatter: ({ rowIdx }) => {
            return (
              <Observer>
                {
                  () => (
                    <span>
                      {this.warehouseAreaData.find((item) => item.value === this.mainSubStructureModel.gridModel.rows[rowIdx].warehouseAreaId)?.label}
                    </span>
                  )
                }
              </Observer>
            );
          },
        },
        {
          name: '库位',
          key: 'warehouseBinId',
          width: 150,
          formatter: ({ rowIdx }) => {
            return (
              <Observer>
                {
                  () => (
                    <Select
                      onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx].warehouseBinId = value}
                      onClick={(event) => event.stopPropagation()}
                      optionFilterProp="label"
                      options={this.warehouseBinData}
                      showSearch
                      size="small"
                      style={{ width: '100%' }}
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].warehouseBinId}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          key: 'pic',
          name: '图片',
          width: 100,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          name: '货主',
          key: 'ownerName',
          width: 150,
        },
        {
          name: 'SKU编码',
          key: 'skuNo',
          width: 180,
        },
        {
          name: '数量',
          key: 'number',
          width: 120,
          formatter: ({ rowIdx }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      max={99999}
                      min={0}
                      onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx].number = formatNumber(value)}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].number}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          name: '库存数量',
          key: 'stockNum',
          width: 80,
          formatter: ({ row }) => {
            return (
              <span>
                {formatNumber(row.stockNum)}
              </span>
            );
          },
        },
        {
          name: '货主库存',
          key: 'ownerNumber',
          width: 80,
          formatter: ({ row }) => {
            return (
              <span>
                {formatNumber(row.ownerNumber)}
              </span>
            );
          },
        },
        {
          name: '成本价',
          key: 'costPrice',
          width: 120,
          formatter: ({ rowIdx }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      max={999999}
                      min={0}
                      onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx].costPrice = formatNumber(value)}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].costPrice}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          name: '金额',
          key: 'sum',
          width: 80,
          formatter: ({ row }) => {
            return (
              <span>
                {toFixed(multiple(row.costPrice, row.number), 2)}
              </span>
            );
          },
        },
        {
          name: '商品名称',
          key: 'productName',
          width: 180,
        },
        {
          name: '商品编码',
          key: 'productNo',
          width: 180,
        },
        {
          name: '颜色',
          key: 'color',
          width: 100,
          formatter: ({ row }: { row: StockDetailItem; }) => {
            return (
              <span>
                {row.color || row.colorType}
              </span>
            );
          },
        },
        {
          name: '尺码',
          key: 'size',
          width: 100,
          formatter: ({ row }: { row: StockDetailItem; }) => {
            return (
              <span>
                {row.size || row.sizeType}
              </span>
            );
          },
        },
        {
          name: '规格',
          key: 'spec',
          width: 100,
        },
        {
          name: '条形码',
          key: 'barCode',
          width: 180,
        },
        {
          name: '备注',
          key: 'note',
          width: 180,
          formatter: ({ rowIdx }) => {
            return (
              <Observer>
                {
                  () => (
                    <Input
                      onChange={(event) => this.mainSubStructureModel.gridModel.rows[rowIdx].note = event.target.value}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].note}
                    />
                  )
                }
              </Observer>
            );
          },
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: false,
      showPagination: false,
      showPager: true,
      setColumnsDisplay: true,
      showRefresh: false,
      gridIdForColumnConfig: '/egenie-cloud-wms/stockIn/index/2',
    },
    hiddenSubTable: true,
    api: { onQuery: () => Promise.reject() },
  });
}
