import { Button, Input, InputNumber, message, Select } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import type { PureData } from 'egenie-common';
import { formatNumber, multiple, toFixed } from 'egenie-common';
import type { BaseData, PaginationData, ValueAndLabelData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, request } from 'egenie-utils';
import _ from 'lodash';
import { action, observable } from 'mobx';
import { Observer } from 'mobx-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React from 'react';
import type { VendorItem } from '../../../utils';
import { getConsignor, getOwner, getWarehouse, getWarehouseArea, isShareOwner, playVoice, voiceData } from '../../../utils';
import type { StockDetailItem, StockMainItem } from '../../stockIn/types';
import { onlyId, skuCondition, STOCK_OUT_ORDER_TYPE } from '../../stockIn/utils';

export class AddAndEditStore {
  constructor(vendorIdData: VendorItem[], callback: () => void, mainItem?: StockMainItem) {
    this.vendorIdData = vendorIdData;
    this.mainItem = mainItem;
    this.callback = callback;

    if (this.mainItem) {
      const isSpecialOrder = [
        STOCK_OUT_ORDER_TYPE.vendorStockOut,
        STOCK_OUT_ORDER_TYPE.consignorStockOut,
      ].includes(Number(this.mainItem.otherStockOrderType));

      this.warehouseIdDisabled = true;
      this.warehouseAreaIdDisabled = true;
      this.ownerIdDisabled = true;
      this.vendorIdDisabled = true;
      this.otherStockOrderTypeDisabled = isSpecialOrder;
      this.showVendorId = isSpecialOrder;
    }
  }

  private getOwnerData = (otherStockOrderType?: string | number, params?: any): Promise<ValueAndLabelData> => {
    if (STOCK_OUT_ORDER_TYPE.consignorStockOut == otherStockOrderType) {
      return getConsignor()
        .then((data) => this.ownerData = data);
    } else {
      return getOwner(params)
        .then((data) => this.ownerData = data);
    }
  };

  @observable public warehouseIdDisabled = false;

  @observable public warehouseAreaIdDisabled = false;

  @observable public ownerIdDisabled = false;

  @observable public vendorIdDisabled = false;

  @observable public otherStockOrderTypeDisabled = false;

  @observable public showVendorId = false;

  @action public handleOtherStockOrderTypeChange = (otherStockOrderType: number): void => {
    this.formRef.current.setFieldsValue({ vendorId: undefined });
    this.showVendorId = false;
    this.ownerIdDisabled = false;

    if (![
      STOCK_OUT_ORDER_TYPE.vendorStockOut,
      STOCK_OUT_ORDER_TYPE.consignorStockOut,
    ].includes(Number(otherStockOrderType))) {
      return;
    }

    const formData = this.formRef.current.getFieldsValue();
    if (!(formData.warehouseId && formData.warehouseAreaId)) {
      message.warn({
        key: '请选择仓库和库区',
        content: '请选择仓库和库区',
      });
      setTimeout(() => this.formRef.current.setFieldsValue({ otherStockOrderType: undefined }));
      return;
    }

    this.showVendorId = true;
    this.getUnusedWarehouseBin(true);
    if (otherStockOrderType == STOCK_OUT_ORDER_TYPE.vendorStockOut) {
      this.ownerIdDisabled = true;
      this.getOwnerData(otherStockOrderType)
        .then(() => {
          this.formRef.current.setFieldsValue({ ownerId: this.ownerData.find((item) => isShareOwner(item.value))?.value });
        });
    } else if (otherStockOrderType == STOCK_OUT_ORDER_TYPE.consignorStockOut) {
      this.ownerIdDisabled = false;
      this.formRef.current.setFieldsValue({ ownerId: undefined });
      this.getOwnerData(otherStockOrderType);
    }
  };

  @action public handleVendorIdChange = () => {
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.rows = [];
  };

  @observable.ref public vendorIdData: VendorItem[] = [];

  private callback?: () => void = null;

  public init = () => {
    getWarehouse()
      .then((data) => this.warehouseData = data);

    if (this.mainItem) {
      const {
        warehouseId,
        warehouseAreaId,
        ownerId,
        vendorId,
        otherStockOrderType,
        otherStockOrderDate,
        note,
      } = this.mainItem;
      this.formRef.current.setFieldsValue({
        warehouseId: _.toString(warehouseId) || undefined,
        warehouseAreaId: _.toString(warehouseAreaId) || undefined,
        ownerId: _.toString(ownerId) || undefined,
        vendorId: _.toString(vendorId) || undefined,
        otherStockOrderType: _.toString(otherStockOrderType) || undefined,
        otherStockOrderDate: moment(otherStockOrderDate || Date.now()),
        note: _.toString(note),
      });

      getWarehouseArea({ warehouseId: this.mainItem.warehouseId })
        .then((data) => this.warehouseAreaData = data);
      this.getOwnerData(this.mainItem.otherStockOrderType, { warehouseId: this.mainItem.warehouseId });
      this.getUnusedWarehouseBin(false);

      request<PaginationData<StockDetailItem>>({
        url: '/api/cloud/wms/rest/bill/other/detail/page',
        method: 'POST',
        data: {
          page: 1,
          pageSize: 10000,
          wmsOtherStockOrderId: this.mainItem.id,
        },
      })
        .then((info) => this.mainSubStructureModel.gridModel.rows = (info.data?.list || []).map((item) => ({
          ...item,
          warehouseBinId: _.toString(item.warehouseBinId) || undefined,
          warehouseAreaId: _.toString(item.warehouseAreaId) || undefined,
          number: formatNumber(item.number),
          stockNum: formatNumber(item.stockNum),
          ownerNumber: formatNumber(item.ownerNumber),
          costPrice: formatNumber(item.costPrice),
          _id: nanoid(),
        })));
    }
  };

  // 以数据区分新增、编辑
  private mainItem?: StockMainItem = null;

  @observable public loading = false;

  public formRef = React.createRef<FormInstance>();

  @observable public warehouseData: ValueAndLabelData = [];

  @action public handleWarehouseChange = (warehouseId: string) => {
    this.formRef.current.setFieldsValue({
      warehouseAreaId: undefined,
      ownerId: undefined,
      vendorId: undefined,
    });

    this.ownerIdDisabled = false;
    getWarehouseArea({ warehouseId })
      .then((data) => this.warehouseAreaData = data);
    this.getOwnerData(this.formRef.current.getFieldsValue().otherStockOrderType, { warehouseId });
  };

  @observable public warehouseAreaData: ValueAndLabelData = [];

  @observable public ownerData: ValueAndLabelData = [];

  @observable public warehouseBinData: ValueAndLabelData = [];

  @action public getUnusedWarehouseBin = (clearTableData: boolean) => {
    if (clearTableData) {
      this.mainSubStructureModel.gridModel.resetAll();
      this.mainSubStructureModel.gridModel.rows = [];
    }

    request<BaseData<Array<{ serialNo: string; id: number | string; }>>>({
      url: '/api/cloud/baseinfo/rest/warehouse/bin/checkUnusedBin',
      method: 'post',
      data: this.formRef.current.getFieldsValue(),
    })
      .then((info) => {
        this.warehouseBinData = (info.data || []).map((item) => ({
          value: `${item.id}`,
          label: item.serialNo,
        }));
      });
  };

  @action public handleSubmit = async(): Promise<void> => {
    this.loading = true;
    try {
      const {
        note,
        ownerId,
        vendorId,
        otherStockOrderDate,
        otherStockOrderType,
        warehouseAreaId,
        warehouseId,
      } = await this.formRef.current.validateFields();
      if (this.mainSubStructureModel.gridModel.rows.length === 0) {
        const error = '明细不能为空';
        message.error({
          key: error,
          content: error,
        });
        throw new Error(error);
      }

      const data = {
        note,
        ownerId,
        vendorId,
        vendorTenantId: this.vendorIdData.find((item) => item.value === vendorId)?.vendorTenantId,
        orderType: '2',
        otherStockOrderDate: moment(otherStockOrderDate)
          .format('YYYY-MM-DD'),
        otherStockOrderType,
        warehouseAreaId,
        warehouseId,
        id: this.mainItem?.id,
        sourceType: this.mainItem?.sourceType,
        stockDetails: this.mainSubStructureModel.gridModel.rows.map((row) => {
          const {
            warehouseBinId,
            skuId,
            productNo,
            batchNo = '',
            skuNo,
            expiryDate = '',
            productId,
            manufactureDate = '',
            number,
            costPrice,
            note,
            stockNum,
          } = row;
          return {
            ownerId,
            productNo,
            skuNo,
            warehouseBinId,
            productId,
            warehouseAreaId,
            skuId,
            batchId: '0',
            batchNo,
            expiryDate,
            manufactureDate,
            number,
            costPrice,
            note,
            stockNum,
          };
        }),
      };

      await request({
        url: '/api/cloud/wms/rest/bill/other/add',
        method: 'post',
        data,
      });
      message.success('保存成功');
      if (typeof this.callback === 'function') {
        this.callback();
      }
    } finally {
      this.loading = false;
    }
  };

  @action public scanAddProduct = async(scanValue: string) => {
    if (!scanValue) {
      playVoice(voiceData.wrong);
      throw new Error('请输入');
    }

    try {
      this.mainSubStructureModel.gridModel.loading = true;

      const {
        warehouseId,
        warehouseAreaId,
        ownerId,
        vendorId,
        scanKey,
        otherStockOrderType,
      } = await this.formRef.current.validateFields();

      const data = {
        batch: false, // 单个添加
        page: 1,
        pageSize: 1000,
        key: scanKey,
        value: scanValue,
        [Object.values(skuCondition)
          .find((val) => val.value === scanKey)?.paramsKey]: scanValue,
        warehouseId,
        warehouseAreaId,
        ownerId,
        vendorId,
        otherStockOrderType,
        stock: true, // 出库单
      };

      const info = await request<PureData<StockDetailItem>>({
        url: '/api/cloud/wms/rest/bill/other/out/other/stock/sku/info',
        method: 'post',
        data,
      });
      const addData = (info?.list || []).map((item) => ({
        ...item,
        number: formatNumber(item.number),
        stockNum: formatNumber(item.stockNum),
        ownerNumber: formatNumber(item.ownerNumber),
      }));

      if (addData.length === 0) {
        const error = '未查询到商品';
        playVoice(voiceData.wrong);
        message.error(error);
        throw new Error(error);
      } else {
        addData.forEach((item) => this.handleAddDetailItem(item));
      }
    } finally {
      this.mainSubStructureModel.gridModel.loading = false;
      this.formRef.current.setFieldsValue({ scanValue: '' });
    }
  };

  private getStockDetailItemMaxNumber = (stockDetailItem: StockDetailItem): number => {
    const rows: StockDetailItem[] = this.mainSubStructureModel.gridModel.rows;
    const skuOwnerNumber = stockDetailItem.ownerNumber;
    const skuUsedNumber = rows.reduce((prev, current) => {
      if (current.skuId === stockDetailItem.skuId) {
        if (current.warehouseBinId === stockDetailItem.warehouseBinId) {
          return prev;
        }
        return prev + current.number;
      } else {
        return prev;
      }
    }, 0);
    return skuUsedNumber < skuOwnerNumber ? skuOwnerNumber - skuUsedNumber : 0;
  };

  @action public handleAddDetailItem = (addItem: StockDetailItem) => {
    const rows: StockDetailItem[] = this.mainSubStructureModel.gridModel.rows;
    const warehouseBinId = _.toString(addItem.warehouseBinId) || this.warehouseBinData[0]?.value;
    const equalItem: StockDetailItem | null = rows.find((val) => onlyId(val) === onlyId({
      skuId: addItem.id,
      warehouseBinId,
    }));

    if (equalItem) {
      const newNumber = formatNumber(equalItem.number) + (formatNumber(addItem.number) || 1);
      const maxNumber = this.getStockDetailItemMaxNumber(equalItem);
      if (maxNumber > 0) {
        equalItem.number = Math.min(newNumber, maxNumber);
        playVoice(voiceData.di);
      }
    } else {
      const newNumber = formatNumber(addItem.number) || 1;
      const maxNumber = this.getStockDetailItemMaxNumber({
        ...addItem,
        skuId: addItem.id,
      });

      if (maxNumber > 0) {
        this.mainSubStructureModel.gridModel.rows.push({
          ...addItem,
          _id: nanoid(),
          number: Math.min(newNumber, maxNumber),
          stockNum: formatNumber(addItem.stockNum),
          ownerNumber: formatNumber(addItem.ownerNumber),
          costPrice: formatNumber(addItem.costPrice),
          skuId: addItem.id,
          warehouseAreaId: _.toString(addItem.warehouseAreaId) || this.formRef.current?.getFieldValue('warehouseAreaId'),
          warehouseBinId,
        });
      }
    }
  };

  @observable public showAddGoodsModal = false;

  @action public openAddGoodsModal = () => {
    this.formRef.current.validateFields()
      .then(() => {
        this.showAddGoodsModal = true;
      });
  };

  @action public closeAddGoodsModal = () => {
    this.showAddGoodsModal = false;
  };

  @action public addGoodsModalCallback = (rows: StockDetailItem[]) => {
    this.closeAddGoodsModal();
    rows.forEach((item) => {
      this.handleAddDetailItem(item);
    });
  };

  public mainSubStructureModel = new MainSubStructureModel({
    buttons: [],
    grid: {
      columns: [
        {
          key: 'operation',
          frozen: true,
          width: 80,
          name: '操作',
          formatter: ({ rowIdx }) => {
            return (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  this.mainSubStructureModel.gridModel.rows.splice(rowIdx, 1);
                }}
                type="link"
              >
                删除
              </Button>
            );
          },
        },
        {
          name: '库区',
          key: 'warehouseAreaId',
          width: 150,
          formatter: ({ rowIdx }) => {
            return (
              <Observer>
                {
                  () => (
                    <span>
                      {this.warehouseAreaData.find((item) => item.value === this.mainSubStructureModel.gridModel.rows[rowIdx].warehouseAreaId)?.label}
                    </span>
                  )
                }
              </Observer>
            );
          },
        },
        {
          name: '库位',
          key: 'warehouseBinId',
          width: 150,
          formatter: ({ rowIdx }) => {
            return (
              <Observer>
                {
                  () => (
                    <Select
                      disabled={!_.isEmpty(this.mainItem)}
                      onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx].warehouseBinId = value}
                      onClick={(event) => event.stopPropagation()}
                      optionFilterProp="label"
                      options={this.warehouseBinData}
                      showSearch
                      size="small"
                      style={{ width: '100%' }}
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].warehouseBinId}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          key: 'pic',
          name: '图片',
          width: 100,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          name: 'SKU编码',
          key: 'skuNo',
          width: 200,
        },
        {
          name: '数量',
          key: 'number',
          width: 100,
          formatter: ({
            rowIdx,
            row,
          }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      max={this.getStockDetailItemMaxNumber(this.mainSubStructureModel.gridModel.rows[rowIdx])}
                      min={0}
                      onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx].number = formatNumber(value)}
                      onClick={(event) => event.stopPropagation()}
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].number}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          name: '库存数量',
          key: 'stockNum',
          width: 80,
          formatter: ({ row }) => {
            return (
              <span>
                {formatNumber(row.stockNum)}
              </span>
            );
          },
        },
        {
          name: '货主库存',
          key: 'ownerNumber',
          formatter: ({ row }) => {
            return (
              <span>
                {formatNumber(row.ownerNumber)}
              </span>
            );
          },
          width: 80,
        },
        {
          name: '成本价',
          key: 'costPrice',
          width: 100,
          formatter: ({ rowIdx }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      max={999999}
                      min={0}
                      onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx].costPrice = formatNumber(value)}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].costPrice}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          name: '金额',
          key: 'sum',
          width: 80,
          formatter: ({ row }) => {
            return (
              <span>
                {toFixed(multiple(row.costPrice, row.number), 2)}
              </span>
            );
          },
        },
        {
          name: '商品名称',
          key: 'productName',
          width: 150,
        },
        {
          name: '商品编码',
          key: 'productNo',
          width: 150,
        },
        {
          name: '颜色',
          key: 'color',
          width: 100,
          formatter: ({ row }: { row: StockDetailItem; }) => {
            return (
              <span>
                {row.color || row.colorType}
              </span>
            );
          },
        },
        {
          name: '尺码',
          key: 'size',
          width: 100,
          formatter: ({ row }: { row: StockDetailItem; }) => {
            return (
              <span>
                {row.size || row.sizeType}
              </span>
            );
          },
        },
        {
          name: '规格',
          key: 'spec',
          width: 100,
        },
        {
          name: '条形码',
          key: 'barCode',
          width: 150,
        },
        {
          name: '备注',
          key: 'note',
          width: 150,
          formatter: ({ rowIdx }) => {
            return (
              <Observer>
                {
                  () => (
                    <Input
                      onChange={(event) => this.mainSubStructureModel.gridModel.rows[rowIdx].note = event.target.value}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].note}
                    />
                  )
                }
              </Observer>
            );
          },
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: false,
      showPagination: false,
      showPager: true,
      setColumnsDisplay: true,
      showRefresh: false,
      gridIdForColumnConfig: '/egenie-cloud-wms/stockOut/index/2',
    },
    hiddenSubTable: true,
    api: { onQuery: () => Promise.reject() },
  });
}
